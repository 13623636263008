/* eslint-disable */

import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

import indexLogin from './index/login';
import baseLogin from './base/login';
import indexHome from './base/home';
import indexActive from './base/active';
import indexCamp from './index/camp'
import personalInfo from './index/personalInfo'
import { getCam, getCamInfo } from "r/index/login";
import { Message } from 'element-ui';
import { parseTime } from '../common/utils';

Vue.prototype.$message = Message;

Vue.use(VueRouter)


const routes = [
  {
    path: '',
    name: '',
    meta: {
      title: ''
    },
    redirect: "/",
    component: () => import('v/base/Index.vue'),
    children: [...indexHome, ...indexActive]
  },
  {
    path: '/baseLogin',
    name: 'loginBase',
    meta: {
      title: '登录'
    },
    component: () => import('v/base/login/Index.vue'),
    children: [...baseLogin]
  },
  {
    path: '/myInfo/',
    name: 'myInfoIndex',
    meta: {
      title: '个人中心'
    },
    redirect: '/myInfo/signUpList',
    component: () => import('v/base/myInfo/index.vue'),
    children: [{
      path: 'signUpList',
      name: 'signUpList',
      component: () => import(/* webpackChunkName: "login" */ 'v/base/myInfo/signUp.vue'),
      meta: {
        title: '我的报名'
      }
    }]
  },
  {
    path: '/login',
    name: 'loginIndex',
    meta: {
      title: '登录'
    },
    component: () => import('v/index/login/Index.vue'),
    children: [...indexLogin]
  },
  {
    path: '/Info',
    name: 'Info',
    meta: {
      title: '个人信息'
    },
    // redirect: '/Info/personalInfo',
    component: () =>
      import('v/index/Info/Index.vue'),
    children: [...personalInfo]
  },
  {
    path: '/',
    name: 'cerificateIndex',
    meta: {
      title: '营地报名'
    },
    // redirect: '/cerificate/signUp',
    component: () =>
      import('v/index/camp/Index.vue'),
    children: [...indexCamp]
  },
  {
    path: '/:code',
    name: 'recruit',
    meta: {
      title: ''
    },
    component: () =>
      import('v/index/recruit/Index.vue')
  },
  {
    path: "/404",
    name: "notFound",
    component: () =>
      import('v/index/notFound.vue'),
  }, {
    path: "*", // 此处需特别注意置于最底部
    redirect: "/404"
  }
]


const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes,

  // 禁止浏览器 前进和后退，另一部分在main.js中
  scrollBehavior: () => {
    history.pushState(null, null, document.URL)
  }
})


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

//   运用vue-router的错误处理函数 onError 捕获错误(当捕获到了Loading chunk {n} failed的错误时，重新渲染目标页面。)
router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  // const targetPath = router.history.pending.fullPath;
  // 用路由的replace方法，并没有相当于F5刷新页面，失败的js文件并没有从新请求，会导致一直尝试replace页面导致死循环，
  // 而用 location.reload 方法，相当于触发F5刷新页面，虽然用户体验上来说会有刷新加载察觉，但不会导致页面卡死及死循环，从而曲线救国解决该问题
  if (isChunkLoadFailed) {
    location.reload();
    // router.replace(targetPath);
  }
})

function checkCam(code, cb) {
  store.commit('setIndexIdentity', code)
  if (!code) {
    Message({
      message: "请访问正确的营地链接地址",
      type: 'waring'
    });
    cb && cb(false);
    return false;
  }
  getCam(code).then((res) => {
    console.log(res)
    if (res.data.code == "50018") {
      return 
    }
    let root = document.querySelector(":root");
    root.style.setProperty("--color", res.data.system_color);
    root.style.setProperty("--bk_pic", res.data.background_picture); //当前营地的背景图
    window.localStorage.setItem("bk_pic" + code, res.data.background_picture);
    //当前营地logo
    window.localStorage.setItem("system_logo" + code, res.data.system_logo);
    window.localStorage.setItem("doubt_code" + code,
      res.data.fill_individual_Invitationcode
    ); //是否填写邀请码
    window.localStorage.setItem("doubt_info" + code,
      res.data.fill_individual_information
    ); //是否填写个人资料
    window.localStorage.setItem("doubt_check" + code,
      res.data.audit_individual_information
    ); //是否审核个人资料
    // 判断使用微信二维码付款/上传转账截图
    window.localStorage.setItem("transfer_account" + code, res.data.transfer_account);
    if (res.data.transfer_account == 1) {
      window.localStorage.setItem("payee" + code, res.data.payee); //收款单位
      window.localStorage.setItem("bank_first" + code, res.data.bank_first); //开户行
      window.localStorage.setItem("bank_idcard" + code, res.data.bank_idcard); //银行卡号
    }
    // 转账请备注
    window.localStorage.setItem("transfer_back_up" + code, res.data.transfer_back_up); //收款单位
    store.state.info = res.data;
    if (res.data.is_qinghua == 1) {
      store.state.human = 1;
    }
    if (res.data.base_school == 1) {
      store.state.baseSchool = 1;
    }
    // 分营地填写资料字段
    if (res.data.config_json != null) {
      store.state.configJson = res.data.config_json;
    }
    //是否开启多形式报名
    if (res.data.is_multiform != null) {
      store.state.isMultiform = res.data.is_multiform;
    }
    window.localStorage.setItem("multiform" + code, JSON.stringify(res.data.multiform));
    //是否开启报名人数显示
    if (res.data.is_display_num != null) {
      store.state.isDisplay_num = res.data.is_display_num;
    }
    // 是否替换原有结营证书 
    if (res.data.open_certificate == 1) {
      store.state.open_certificate = res.data.open_certificate;
      store.state.finish_certificate_address = res.data.finish_certificate_address;
    }
    //是否隐藏填写邮寄地址
    if (res.data.is_post != null) {
      store.state.isPost = res.data.is_post;
    }
    // 缴费截止时间
    if (res.data.registration_end != null) {
      store.state.registrationEnd = parseTime(res.data.registration_end);
    }
    cb && cb(true);
  }).catch(() => {

    cb && cb(false);
  })
}

router.beforeEach((to, from, next) => {
  if (!(from.path == '/' && from.name == null) &&
    (from.path != '/baseLogin' &&
      from.path != '/baseRegister' &&
      from.path != '/baseReset')) {
    store.commit('setPreviousPath', from.path);
  }
  if (to.query && to.query.disCode) {
    store.commit('setDisCode', to.query.disCode);
  }

  let toLogin = "/login",
    toRegister = "/register",
    toReset = "/reset",
    toBaseLogin = "/baseLogin",
    toBaseRegister = "/baseRegister",
    toBaseReset = "/baseReset",
    toHome = "/",
    toActive = "/active",
    toInfo = "/myInfo";
  let code = false;
  document.title = to.meta.title;
  if (to.path == toBaseLogin || to.path == toBaseRegister || to.path == toBaseReset || to.path.indexOf(toInfo) != -1 || to.path == toHome || to.path == toActive || to.path.indexOf(toActive) != -1) {
    return next();
  } else if (to.path == toLogin || to.path == toRegister || to.path == toReset) {
    code = to.params.code ? to.params.code : to.query.code;
    checkCam(code, (res) => {
      console.log(res, '11')
      if (res) {
        let token = window.localStorage.getItem("index-token-all");
        //检查登陆情况
        if (token) {
          return next("/" + code);
        } else {
          return next();
        }
      }
    });
    return next();
  } else if (to.name == "recruit") {
    code = to.params.code;
    checkCam(code, (res) => {
      console.log(res)
      if (res) {
        return next();
      }
    });
  } else {

    code = to.params.code ? to.params.code : to.query.code;
    let token = window.localStorage.getItem("index-token-all");
    if (!token) {
      checkCam(code, (res) => {
        if (res) {
          return next("/" + code);
        }
      });
    } else {
      // if (to.path == '/signUp/undefined') {
      //   // 报名流程中转页，不调用getCam接口
      //   return next();
      // }
      checkCam(code, (res) => {
        if (res) {
          return next();
        }
      });
    }
  }
})

// 页面跳转之后页面回滚到顶部
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
});
export default router
